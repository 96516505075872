.results__main-wrapper {
    width: 100vw;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results__main-results__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results_inner__wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
}

.results__go-back__wrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.results_disclaimer__wrapper {
    width: 60%;
    font-size: 16px;
    font-style: italic;
    text-align: justify;
    color: var(--dark-lighter-background);
    background: var(--light-gray-lighter-medium);
    border-radius: 20px;
    padding: 20px 30px;
}

.results_disclaimer-x__wrapper {
    display: flex;
    justify-content: flex-end;
    color: var(--dark-lighter-background);
}

.results_actions__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;
}

.results_actions-print__wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.results_actions-bibliography__wrapper {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.results_actions-button__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid black;
    padding: 20px;
    border-radius: 10px;
    font-size: 18px;
    color: var(--very-light-gray);
    background: var(--dark-background);
}

.bibliography__button {
    color: var(--dark-background);
    background: var(--white);
}


.results_go-back__button {
    width: 100px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    color: var(--white);
    background: var(--another-gray);
    font-size: 14px;
}

.results_go-back__button:hover {
    background: var(--white);
    color: var(--dark-lighter-background);
    cursor: pointer;
}

.results_title__wrapper {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 15px;
}

.results_vaccinations-bar__wrapper {
    width: 100%;
    padding: 20px 15px;
    margin: 15px 15px;
    background: var(--white);
    border-radius: 20px;
    border: 2px solid black;
}

.mandatory-background {
    background: var(--white);
}

.recommended-background {
    background: var(--white);
}

.highly-recommended-background {
    background: var(--light-gray-lighter);
}

.contraindicated-background {
    background: var(--orange);
}
