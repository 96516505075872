.popover-content {
    border-radius: 4px;
    padding: 20px;
    width: 260px;
    background-color: white;
    will-change: transform, opacity;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0 10px 20px -15px;
    font-size: 12px;
    color: black;
}

.popover-content[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
}
.popover-content[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
}
.popover-content[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
}
.popover-content[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
}

.popover-text {
    margin: 15px 0 5px;
}

.popover-arrow {
    fill: white;
}

.popover-close {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
}
.popover-close:hover {
    color: var(--orange);
}

.icon-button {
    font-family: inherit;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--light-gray-darker);
    background: var(--light-gray);
    font-weight: bold;
    border: 2px solid var(--light-gray-darker);

    font-size: 10px;
}
.icon-button:hover {
    background-color: white;
    color: var(--orange);
    cursor: pointer;
}

a.popover-branding {
    text-decoration: none;
    color: black;
}

a.popover-branding:hover {
    color: var(--orange);
    text-decoration: underline;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.medutools-img__wrapper {
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;
}

.medutools__text {
    font-size: 11px;
}

.branding_img-size {
    height: 60px;
    width: auto;
}
