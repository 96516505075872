:root {
  --white: #ffffff;
  --orange: #c2a26d;
  --dark-background: #303030;
  --dark-lighter-background: #4c4c4c;
  --light-gray-darker: #888888;
  --light-gray: #bdbdbd;
  --light-gray-lighter-medium: #cccccc;
  --light-gray-lighter: #DCDCDC;
  --medium-light-gray: #dddddd;
  --very-light-gray: #F5F5F5;
  --another-gray: #D3D3D3;
}

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--light-gray);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navigation-buttons__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.additional-padding-left-40 {
  padding-left: 40px;
}
