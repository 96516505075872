.vaccinations-bar__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.vaccinations-bar__title {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;

}

.vaccinations-bar__inner-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.vaccinations-bar_single-vaccine__wrapper {
    width: 100%;
}

.vaccinations-bar_single-vaccine {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid var(--very-light-gray);
}

.vaccinations-bar_name {
    width: 40%;
}

.vaccinations-bar_link {
    width: 20%;
}

.vaccinations-bar_disclaimer {
    width: 40%;
}

a.leaflet__link {
    color: var(--orange);
    text-decoration: none;
}

a.leaflet__link:hover {
    color: var(--dark-background);
}
