.default-button {
    width: 100px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    color: white;
    font-size: 12px;
    opacity: 80%;
}

.default-button:hover {
    background: white;
    color: yellow;
    cursor: pointer;
}

.login-button {
    background: var(--dark-background);
    color: var(--medium-light-gray);
    width: 140px;
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    font-size: 12px;
}

.login-button:hover {
    color: var(--white);
    cursor: pointer;
}

.login-button:disabled {
    background: var(--dark-background);
    color: var(--light-gray);
    opacity: 8%;
    cursor: default;
}

.navigation-button {
    padding: 10px 10px;
    text-align: center;
    border-radius: 15px;
    color: var(--dark-background);
    background: var(--white);
    font-size: 14px;
    border: 2px solid var(--dark-background);
}

.navigation-button:hover {
    background: var(--orange);
    border: 2px solid var(--white);
    color: var(--white);
    cursor: pointer;
}

.navigation-button:disabled {
    background: var(--white);
    border: none;
    color: var(--light-gray-darker);
    opacity: 30%;
    cursor: default;
}
